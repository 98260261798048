<template>
  <a-layout class="app-layout">
    <a-layout-header class="layout-header">
      <a-row>
        <a-col :span="12">
          <div class="header-title">{{ $store.state.auth.info.coop_info.name }}</div>
        </a-col>

        <a-col :span="12" align="right">
          <a-button @click="toLoginPage">登出</a-button>
        </a-col>
      </a-row>
    </a-layout-header>

    <a-layout>
      <a-layout-sider class="app-layout-sider" style="background: #fff">
        <a-menu
          class="sider-menu"
          v-model:selectedKeys="selectedKeys"
          mode="inline"
          :style="{ height: '100%', borderRight: 0 }"
        >
          <!-- <a-menu-item class="menu-item" key="AppIndex" @click="toPage('AppIndex')">首頁</a-menu-item> -->
          <a-menu-item class="menu-item" key="AppMembers" @click="toPage('AppMembers')">社員管理</a-menu-item>
          <!-- <a-menu-item class="menu-item" key="AppExchanges" @click="toPage('AppExchanges')">兌換所管理</a-menu-item> -->

          <!-- <a-sub-menu key="sub1">
            <template #title>
              <span>
                <user-outlined />
                subnav 1
              </span>
            </template>
            <a-menu-item key="1">option1</a-menu-item>
            <a-menu-item key="2">option2</a-menu-item>
            <a-menu-item key="3">option3</a-menu-item>
            <a-menu-item key="4">option4</a-menu-item>
          </a-sub-menu> -->
        </a-menu>
      </a-layout-sider>

      <a-layout-content class="layout-content">
        <router-view />
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>

<script>
import { defineComponent, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { message } from 'ant-design-vue'

export default defineComponent({
  components: {},
  setup() {
    const route = useRoute();
    const router = useRouter();

    /* Menu */
    const selectedKeys = ref([route.name]);

    return {
      route,
      router,

      /* Menu */
      selectedKeys
    }
  },
  methods: {
    toLoginPage() {
      message.success('Logout success');
      this.$router.push({ name: 'AuthLogin' });
    },
    toPage(routerName) {
      this.$router.push({ name: routerName });
    },
  }
})
</script>

<style lang="scss">
.app-layout {
  overflow: hidden;

  .layout-header {
    background-color: white;
    border-bottom: 1px solid #EEEEEE;

    .header-title {
      display: inline-block;
      color: #212121;
      font-size: 1.3rem;
      font-weight: bold;
      letter-spacing: 0.0425rem;
    }
  }

  .app-layout-sider {
    .sider-menu {
      padding-top: 16px;

      .menu-item {
        height: 50px !important;
        font-size: 1.1rem;
      }
    }
  }

  .layout-content {
    overflow: auto;
    background: white;
    padding: 30px 50px 60px 50px;
  }
}
</style>